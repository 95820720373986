<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group label="Name" label-for="name" class="mb-0">
              <b-form-input
                id="name"
                v-model="search.name"
                class="form-control"
                placeholder="Please Enter Name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Status" label-for="status" class="mb-0">
              <v-select
                v-model="search.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                input-id="status"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-end justify-content-end"
          >
            <b-button variant="outline-primary" @click="resetFilter()">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              Search
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <!-- <div class="m-2">
            <b-row>
              <b-col cols="12" v-if="$can('create', 'sportLeague')">
                <div class="d-flex align-items-center justify-content-end">
                  <b-button variant="primary" @click="showAddSportLeagueModal()">
                    <span class="text-nowrap">
                      <feather-icon icon="PlusIcon" /> New
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div> -->
      <b-table
        ref="refSportLeagueListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        head-variant="light"
        :items="sportLeagueLists"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link class="cursor-pointer text-primary" v-if="data.item.user">
            <!-- @click="showEditSportLeagueModal(data.item)" -->
            {{ data.item.user.username }}
          </b-link>
        </template>
        <template #cell(typeEmail)="data">
          {{ resolveType(data.item.type).label }}
        </template>
        <template #cell(status)="data">
          <!-- <div :class="`dot bg-${resolveStatus(data.item.stat).variant}`">
            {{ resolveStatus(data.item.stat).label }}
          </div> -->
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.stat).variant}`"
            />{{ resolveStatus(data.item.stat).label }}
          </div>
        </template>
        <!-- <template #cell(otp)="data">
            {{ data.item.otp }}
          </template> -->

        <!-- <template #cell(logo)="data">
              <b-link
                :href="baseUrl + data.item.logo"
                target="_blank"
                class="cursor-pointer"
                v-if="data.item.logo != null"
              >
                <b-img :src="baseUrl + data.item.logo" fluid class="mr-2 rounded" />
              </b-link>
              <b-link v-else></b-link>
            </template> -->

        <!-- <template #cell(created_by)="data">
              {{ data.item.created_by }}
            </template> -->
        <template #cell(expired_at)="data">
          {{ data.item.expired_at }}
        </template>
        <template #cell(created_at)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <!-- <template #cell(updated_at)="data">
              {{ data.item.updated_at }}
            </template> -->

        <template #cell(action)="data">
          <b-button
            v-b-tooltip.hover.v-danger
            :title="$t('Reject')"
            v-if="$can('delete', 'maillog')"
            variant="danger"
            class="btn-icon btn-sm"
            @click="deleteVoiceOptLog(data.item.id)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSportLeague"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Modal: Add sports Group -->
    <!-- <add-sports-group-modal
                @refetch-data="refetchData"
            /> -->

    <!-- Modal: Edit sports Group -->
    <!-- <edit-sports-group-modal
                :sports-group-detail.sync="sportLeagueDetail"
                @refetch-data="refetchData"
            /> -->
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import logsStoreModule from "@/views/logs/logsStoreModule";
// import AddsportLeagueModal from '@/views/sports/AddsportLeagueModal.vue'
// import EditsportLeagueModal from '@/views/sports/EditsportLeagueModal.vue'

export default {
  components: {
    // AddsportLeagueModal,
    // EditsportLeagueModal,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      sportLeagueDetail: null,
    };
  },
  methods: {
    showAddSportLeagueModal() {
      this.$bvModal.show("modal-add-sports-league");
    },
    showEditSportLeagueModal(data) {
      this.sportLeagueDetail = data;
      this.$bvModal.show("modal-edit-sports-league");
    },
    deleteVoiceOptLog(bannerId) {
      this.$swal({
        title: "Bạn có chắc không ?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const bannerData = {
            id: bannerId,
          };
          store.dispatch("logs/deleteMailLog", bannerData).then((response) => {
            if (response.data.code == "00") {
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your file has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.refetchData();
            }
          });
        }
      });
    },
  },
  setup() {
    if (!store.hasModule("logs")) store.registerModule("logs", logsStoreModule);

    const toast = useToast();

    const search = ref({
      name: "",
      status: "",
    });

    const statusFilter = ref(null);
    const nameFilter = ref(null);

    const perPage = ref(25);
    const totalSportLeague = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);

    const refSportLeagueListTable = ref(null);

    const tableColumns = [
      { key: "index", label: "#" },
      "name",
      "typeEmail",
      "status",
      "expired_at",
      "created_at",
      // "updated_at",
      "action",
    ];

    const refetchData = () => {
      refSportLeagueListTable.value.refresh();
    };

    watch([currentPage, nameFilter, statusFilter], () => {
      refetchData();
    });

    const sportLeagueLists = (ctx, callback) => {
      store
        .dispatch("logs/fetchMailLog", {
          name: nameFilter.value,
          status: statusFilter.value,
          page: currentPage.value,
        })
        .then((response) => {
          callback(response.mailLog);
          totalSportLeague.value = response.total;
          perPage.value = response.pageSize;
        });
    };

    const dataMeta = computed(() => {
      const localItemsCount = refSportLeagueListTable.value
        ? refSportLeagueListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSportLeague.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "inActive", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "inActive", variant: "danger" };
      return { label: "Pending", variant: "secondary" };
    };
    const resolveType = (status) => {
      if (status == 1) return { label: "Xác thực", variant: "primary" };
      if (status == 2) return { label: "Quên password", variant: "danger" };
      return { label: "Pending", variant: "secondary" };
    };

    const resetFilter = () => {
      search.value.name = "";
      search.value.status = "";
      nameFilter.value = null;
      statusFilter.value = null;
    };

    const searchFilter = () => {
      nameFilter.value = search.value.name;
      statusFilter.value = search.value.status;
    };

    return {
      resolveType,
      tableColumns,
      perPage,
      currentPage,
      totalSportLeague,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refSportLeagueListTable,

      sportLeagueLists,
      refetchData,
      nameFilter,
      search,

      resetFilter,
      statusFilter,

      resolveStatus,
      statusOptions,

      formatDateTime,
      searchFilter,
      baseUrl,
      //   deleteVoiceOptLog,
    };
  },
};
</script>
