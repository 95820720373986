import LogService from "@/libs/log.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVoiceOptLog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        LogService.getVoiceOptLog(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteVoiceOtpLog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        LogService.deleteVoiceOtpLog(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchMailLog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        LogService.getMailLog(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteMailLog(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        LogService.deleteMailLog(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
