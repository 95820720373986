/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class LogsService {
  getMailLog(queryParams) {
    const pars = {
      username: queryParams.name,
      stat: queryParams.status,
      pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
      .get("/api/mail-log/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          mailLog: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  getVoiceOptLog(queryParams) {
    const pars = {
        username: queryParams.name,
      name_en: queryParams.name_en,
      pageNumber: queryParams.page,
        status: queryParams.status,
    };
    return instance
      .get("/api/voice-otp-log/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          voiceOptLog: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  deleteVoiceOtpLog(notificationData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", notificationData.id);

    return instance.post("/api/voice-otp-log/delete", data, {
      headers: authHeader(),
    });
  }
  deleteMailLog(notificationData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", notificationData.id);

    return instance.post("/api/mail-log/delete", data, {
      headers: authHeader(),
    });
  }
}

export default new LogsService();
